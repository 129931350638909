.custom-shape-divider-bottom-1684394908 {
  position: absolute;
  bottom: -8px;
  left: 0;
  top: 0;
  width: 100%;
  overflow: hidden;
  line-height: 0;
  transform: rotate(180deg);
}

.custom-shape-divider-bottom-1684394908 svg {
  position: relative;
  display: block;
  width: calc(135% + 1.35px);
  height: 240px;
  transform: rotateY(180deg);
}

.custom-shape-divider-bottom-1684394908 .shape-fill {
  fill: #CAB0FF;
  stroke: #000;
  stroke-width: 3px;
}