:root {
  --animate-delay: 0.2s;
}

body {
  background-color: #fbfbfb !important;
}

@font-face {
  font-family: 'Montserrat';
  src: url('https://fonts.googleapis.com/css2?family=Montserrat&display=swap');
}

.mySwiper {
  margin-bottom: 30px;
  padding-bottom: 30px !important;
}

.swiper-pagination-bullet-active {
  background: black !important;
}

.swiper-button-next, .swiper-button-prev {
  color: black !important;
  border-radius: 50%;
  border: 2px solid black;
  padding: 20px;
}

.swiper-button-prev { 
  margin-left: 10px
}

.swiper-button-next { 
  margin-right: 10px
}

.swiper-button-next:after, .swiper-button-prev:after {
  font-size: 18px !important;
  font-weight: 900;
}

.review-card:before {
  content: "";
  width: 0px;
  height: 0px;
  position: absolute;
  border-left: 20px solid transparent;
  border-right: 20px solid transparent;
  border-top: 20px solid black;
  border-bottom: 20px solid transparent;
  left: 30px;
  bottom: -41px;
}

 .review-card:after {
  content: "";
  width: 0px;
  height: 0px;
  position: absolute;
  border-left: 15px solid transparent;
  border-right: 15px solid transparent;
  border-top: 15px solid #fbae1f;
  border-bottom: 15px solid transparent;
  left: 35px;
  bottom: -30px;
}

.primary-nav {
  -ms-overflow-style: none;  /* Internet Explorer 10+ */
  scrollbar-width: none;  /* Firefox */
}

.primary-nav::-webkit-scrollbar { 
  display: none;  /* Safari and Chrome */
} 